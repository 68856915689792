import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ComponentLayout from '@myaccount/ComponentLayout';
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './dialogChildren';
import { FormStoreProvider, useFormState } from "@myaccountServices";
import CircularProgress from '@myaccount/progress/CircularProgress';
import { trackBtnClick } from "@myaccount/helper/eventTracking";

import defaults from './defaults';

const ContactModuleWrap = withSubtheme((props) => {
    const {
        showTel,
        showEmail,
        showVideoAsk,
        showWhatsapp,
        className,
        theme,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        moduleTitle,
        data,
        negotiator_id,

        cardModule,
        cardContent,
        cardActions,
        cardModuleTitle,
        cardModuleUserTitle,
        cardModuleUserJobTitle,
        cardModuleButtonTelProps,
        cardModuleButtonEmailProps,
        cardModuleButtonVideoaskProps,
        cardModuleButtonWhatsappProps,
        avatar,
        closeCard,
        closeCardData,
        modalTitle,
        maxDialogW,
        _data
    } = props;

    let moduleData = data ? data : _data;
    const { state, services } = useFormState()

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        setOpen(false);
    };

    const trackBtn = (eventId) => {
        trackBtnClick({
            eventId: eventId
        })
    }

    const RenderEmailMeBtn = ({value}) => {
        let dialogContent = '';
        if (state.loading) {
            dialogContent = <CircularProgress />
        } else {
            dialogContent = <DialogChildren handleClose={handleClose} negotiator_id={negotiator_id} data={data} />
        }
        return (
            <>
                <Button key={value.id} onClick={handleClickOpen} classes={{root: "cardModuleButton"}} {...cardModuleButtonEmailProps}>{value.emailLabel}</Button>
                <ActionModalDefault
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    negotiator_id={negotiator_id}
                    children={dialogContent}
                    loading={state.loading}
                    title={modalTitle ? modalTitle : "Contact Us"}
                    maxDialogWidth={maxDialogW}
                />
            </>
        )
    }

    let avatarSet, avatarSrc;
    if (moduleData.avatar) {
        avatarSet = true;
        switch (typeof moduleData.avatar) {
            case 'string':
                // No problem. Url
                avatarSrc = moduleData.avatar;
                break;

            case 'boolean':
                // avatar: true ?
                avatarSet = !!moduleData.avatar;
                avatarSrc = undefined;
                break;

            default:
                console.warn(`Strange value for Avatar on crm_id ${moduleData.crm_id}: ${JSON.stringify(moduleData.avatar)}`);
                avatarSrc = undefined;
        }
    }

    avatarSrc = avatarSrc || moduleData?.profile_img;

    return(
        <ComponentLayout
            className={className}
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Card classes={{root: "contactModule"}} {...cardModule}>
                <CardContent classes={{root: "cardContent"}} {...cardContent}>
                    {moduleTitle &&
                        <Typography classes={{root: "cardModuleTitle"}} {...cardModuleTitle}>
                            {moduleTitle}
                        </Typography>
                    }
                    {avatarSet &&
                        <Avatar alt={moduleData.name} src={avatarSrc} classes={{root: "avatar"}} {...avatar} />
                    }
                    {moduleData?.name &&
                        <Typography classes={{root: "cardModuleUserTitle"}} {...cardModuleUserTitle}>
                            {moduleData.name}
                        </Typography>
                    }
                    {moduleData?.job_title &&
                        <Typography classes={{root: "cardModuleUserJobTitle"}} {...cardModuleUserJobTitle}>
                            {moduleData.job_title}
                        </Typography>
                    }
                </CardContent>
                <CardActions classes={{root: "cardActions"}} {...cardActions}>
                    {moduleData.btns.map((value) => (
                        <>
                            {(value.telLabel && showTel) &&
                                <Button onClick={() => trackBtn('phone')} key={value.id} href={`tel:${value.tel}`} classes={{root: "cardModuleButton"}} {...cardModuleButtonTelProps}>{value.telLabel}</Button>
                            }
                            {(value.emailLabel && showEmail) &&
                                <RenderEmailMeBtn key={value.id} value={value} />
                            }
                            {(value.videoAskLabel && showVideoAsk) &&
                                <Button onClick={() => trackBtn('videoAsk')} key={value.id} href={`${value.videoAskUrl}`} classes={{root: "cardModuleButton"}} {...cardModuleButtonVideoaskProps}>{value.videoAskLabel}</Button>
                            }
                            {(value.whatsappLabel && showWhatsapp) &&
                                <Button onClick={() => trackBtn('whatsapp')} key={value.id} target={`_blank`} href={`https://wa.me/${value.whatsappUrl}`} classes={{root: "cardModuleButton"}} {...cardModuleButtonWhatsappProps}>{value.whatsappLabel}</Button>
                            }
                        </>
                    ))}
                    {closeCardData?.closeLabel &&
                        <Typography classes={{root: "closeCard"}} {...closeCard}>
                            {closeCardData.closeLabel}
                        </Typography>
                    }
                </CardActions>
            </Card>
        </ComponentLayout>
    )
}, 'contactModule', defaults)

const ContactModule = (props) => {
    return (
        <FormStoreProvider>
            <ContactModuleWrap {...props} />
        </FormStoreProvider>
    )
}

export default ContactModule
